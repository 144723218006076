<template>
    <div class="warp">
        <div>
            <section class="pay-by-card">
                <div class="img-bg"></div>
                <div class="content white">
                    <h3>手机就是交通卡<br/>碰一碰就乘车</h3>
                    <span class="desc">在钱包App开通交通卡后，即可拿起手机，一碰乘车。刷卡无需解锁/无需打开App/无需联网，同享实体卡乘车优惠，换手机支持免费移卡。现已支持全国300多个城市出行。</span>
                    <div class="video" id="playVideo" @click="videoModal = true">
                        <img src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/buscard/play.svg" alt="play">
                        <span>点击视频展示</span>
                    </div>
                </div>
            </section>
            <section>
                <div class="content-center">
                    <p class="title-c">一键开通，手机就是交通卡</p>
                    <p class="desc-c">在线开通交通卡，无需网点排队，与相应实体卡享受同等刷卡优惠</p>
                    <img  class="img-c" src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/buscard/open.jpg" alt="opening">
                </div>
            </section>
            <section class="recharge">
                <div class="img-bg"></div>
                <div class="content-center">
                    <p class="title-c">出行不带卡，充值免排队</p>
                    <p class="desc-c">交易随时查，余额随时充，无需窗口排长队，动动手指充值完成</p>
                </div>
            </section>
            <section>
                <div class="content-center">
                    <p class="title-c">旧卡移新机，免除服务费</p>
                    <p class="desc-c">小米手机之间，小米智能穿戴设备之间支持卡片迁移，已支持40+卡片的移卡</p>
                    <img  class="img-c" src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/buscard/move.jpg" alt="opening">
                </div>
            </section>
            <section class="di">
                <div class="img-bg"></div>
                <div class="content-center white">
                    <p class="title-c">无需解锁屏幕，嘀一声 就通行</p>
                    <p class="desc-c">设置快捷选卡或智能选卡后，手机背面中上部靠近刷卡区域，即可秒刷过闸</p>
                </div>
            </section>
            <section>
                <div class="pass-through">
                    <p class="title">一机在手，百城通行</p>
                    <p class="desc">支持40余张交通卡，覆盖全国300+城市；其中京津冀互联互通卡永久免费开卡，支持全国300+城市刷卡使用</p>
                    <Loading v-if="!cardList.length" height="30"/>
                    <div v-else class="card-con">
                        <ul>
                            <li v-for="(card, index) in cardList" :key="index">
                                <img :src="card.imgUrl" alt="">
                                <p>{{card.name}}</p>
                            </li>
                        </ul>
                        <div class="gradient"></div>
                        <p class="more">
                            <span @click="checkMore('Cards', '[交通卡页面]查看更多-交通卡')">查看更多 <img src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/buscard/check-more.svg" alt=""></span>
                        </p>
                    </div>
                </div>
            </section>
            <section>
                <div class="pass-through support-device grey">
                    <p class="title">支持设备</p>
                    <Loading v-if="!deviceList.length" height="30"/>
                    <div class="phone-list">
                        <ul>
                            <li v-for="(device, index) in deviceList" :key="index">
                                <img :src="device.deviceUrl" alt="">
                                <p>{{device.deviceName}}</p>
                            </li>
                        </ul>
                        <p class="more">
                            <span @click="checkMore('Devices', '[交通卡页面]查看更多机型及刷卡位置')">查看更多机型及刷卡位置 <img src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/buscard/check-more.svg" alt=""></span>
                        </p>
                    </div>
                </div>
            </section>
            <section>
                <ul class="explain">
                    <li v-for="(item, index) in busCardExplainList" :key="index">{{item}}</li>
                </ul>
            </section>
            <Modal
                width="50"
                v-model="videoModal"
                title="手机就是交通卡 碰一碰就乘车"
                footer-hide>
                <video controls id="video-ele">
                    <source src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/buscard/video2.mov" type="video/mp4">
                    <p>Your browser doesn't support HTML5 video. Here is
                        a <a href="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/buscard/video2.mov">link to the video</a> instead.</p>
                </video>
            </Modal>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import { quickCustomPropToTsmWebsite } from '@/common/sensorsSdk'
    import Loading from "@/components/Loading.vue";
    export default {
        name: 'BusCard',
        data () {
            return {
                videoModal: false,
                cardList: [],
                deviceList: [],
                busCardExplainList: []
            }
        },
        components: {
            Loading
        },
        async mounted () {
            quickCustomPropToTsmWebsite({websiteName: '交通卡'})
            const cardList = await axios.get(`https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/data/buscardList.json?num=${Math.random()}`)
            const deviceList = await axios.get(`https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/data/devices.json?num=${Math.random()}`)
            const busCardExplainList = await axios.get(`https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/data/busCardExplainList.json?num=${Math.random()}`)
            this.cardList = cardList.data.slice(0, 12)
            this.deviceList = deviceList.data.slice(0, 8)
            this.busCardExplainList = busCardExplainList.data
        },
        methods: {
            checkMore(routeName, text) {
                quickCustomPropToTsmWebsite({websiteClick: text})
                this.$router.push({name: routeName})
            }
        }
    }
</script>

<style scoped>
    .warp section .img-bg{
        width: 100%;
        height: 41vw;
    }
    .warp section .content{
        z-index: 9;
        position: absolute;
        top: 16vw;
        left: 15vw;
        width: 30vw;
    }
    .warp section .content h3{
        font-size: 3.2vw;
        font-weight: 500;
    }
    .warp section .content p.title{
        font-size: 2vw;
    }
    .warp section .content span.desc{
        width: 80%;
        display: block;
        font-size: .9vw;
        margin-top: .5vw;
    }
    .warp section.pay-by-card .img-bg{
        background: url("https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/buscard/pay-by-card.jpg") no-repeat;
        background-size: cover;
    }
    .warp section.recharge .img-bg{
        background: url("https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/buscard/recharge.jpg") no-repeat;
        background-size: cover;
    }
    .warp section.di .img-bg{
        background: url("https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/buscard/di.jpg") no-repeat;
        background-size: cover;
    }
    .video{
        position: relative;
        display: inline-block;
        background: rgba(255,255,255,0.10);
        border: 1px solid rgba(255,255,255,0.20);
        border-radius: 1.1vw;
        padding: .35vw 1.0vw;
        font-size: .9vw;
        letter-spacing: .3px;
        margin-top: 2vw;
        opacity: .8;
        cursor: pointer;
    }
    .video img{
        position: absolute;
        width: 1vw;
        top: .45vw;
    }
    .video span{
        padding-left: 1.3vw;
    }
    .warp section .content-center{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 41vw;
        letter-spacing: .2px;
    }
    .content-center .title-c{
        font-size: 1.9vw;
        font-weight: 500;
        margin-top: 5vw;
    }
    .content-center .desc-c{
        font-size: .9vw;
        margin: 1vw 0 2vw;
    }
    .content-center .img-c{
        width: 55%;
    }
    .recharge, .di{
        height: 41vw;
    }
    .recharge .content-center,
    .di .content-center{
        z-index: 100;
        position: absolute;
        top: 0;
        width: 100%;
    }
    /* 一机在手，百城通行 */
    .pass-through{
        display: flex;
        flex-direction: column;
        align-items: center;
        letter-spacing: .2px;
    }
    .pass-through .title{
        font-size: 1.9vw;
        font-weight: 500;
        margin-top: 6vw;
    }
    .pass-through .desc{
        font-size: .9vw;
        margin: 1vw 0 2vw;
    }
    .pass-through .card-con{
        position: relative;
        width: 65%;
        padding-bottom: 2vw;
    }
    .pass-through .card-con ul{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .pass-through .card-con ul li{
        text-align: center;
        margin-top: 15px;
    }
    .pass-through .card-con ul li img{
        width: 15vw;
    }
    .pass-through .card-con ul li p{
        padding: 0.6vw 0;
        font-size: 1.2vw;
    }
    .pass-through .card-con .gradient{
        position: absolute;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(180deg, rgba(255,255,255,0.00) 15%, #FFFFFF 90%);
        z-index: 999;
        width: 100%;
        height: 100%;
    }
    .pass-through .card-con .more{
        z-index: 9999;
        font-size: .8vw;
        position: absolute;
        bottom: 3vw;
        left: 51%;
        transform: translate(-50%, -50%);
        letter-spacing: .4px;
        cursor: pointer;
    }
    .pass-through .card-con .more img{
        width: .9vw;
        position: relative;
        top: .2vw;
    }
    .support-device .phone-list{
        width: 60%;
        padding-bottom: 7vw;
    }
    .support-device .phone-list ul{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .support-device .phone-list ul li{
        text-align: center;
        margin-top: 2.5vw;
        width: 25%;
    }
    .support-device .phone-list ul li img{
        height: 10vw;
    }
    .support-device .phone-list ul li p{
        font-size: 1.2vw;
        line-height: 4vw;
    }
    .support-device .phone-list .more{
        font-size: .8vw;
        letter-spacing: .4px;
        position: absolute;
        bottom: 3vw;
        left: 51%;
        transform: translate(-50%, -50%);
        cursor: pointer;
    }
    .support-device .phone-list .more img{
        width: 1.1vw;
        position: relative;
        top: .2vw;
    }
    #video-ele{
        width: 100%;
        border: 0;
        overflow: hidden;
    }
</style>